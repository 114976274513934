import { autoinject, computedFrom, PLATFORM } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { NavigationInstruction, NavModel, Next, PipelineStep, Redirect, Router, RouterConfiguration } from 'aurelia-router';
import { BindingSignaler } from 'aurelia-templating-resources';
import { Notify } from 'utils/notify';
import { reloadTranslations } from 'utils/translate-util';
import { GetHelpResult, Language, MyHttpApi, Session } from './utils/api';

type Header = {
  header: string,
  icon: string,
  text?: string,
  name: string,
  settings?: Object,
  hiddenOnTablet: boolean,
};

interface UINavModel {
  id: NavModel;
  name: string;
}

interface SessionNotification {
  integrationMessageErrors: boolean;
}

class AuthorizeStep implements PipelineStep {
  constructor(private readonly api: MyHttpApi) {
  }

  async run(navigationInstruction: NavigationInstruction, next: Next): Promise<any> {
    if (!this.api.session) {
      let res = await this.api.sessionStatus();
      this.api.session = res.session
    }
    if (navigationInstruction.config.settings.permission(this.api.session)) {
      return next();
    } else {
      return next.cancel(new Redirect("session/login"));
    }
  }
}

@autoinject
export class App {
  private sessionNotifyTimer?: NodeJS.Timeout;
  private showHelp = false;
  private help?: GetHelpResult = undefined
  private showTabletMenu = false;
  private showMobileMenu = false;
    /** Supported keys for all kinds of nifty notification icons in left menu */
  private readonly notifications: SessionNotification = {
    integrationMessageErrors: false,
  };

  constructor(private readonly notify: Notify, private readonly api: MyHttpApi, private router: Router, private readonly i18n: I18N, private readonly bindingSignaler: BindingSignaler) {
  }

  /** Get navmodel of current route; if changed, then change navmodel by asking router to transition to that route. */
  @computedFrom("router.currentInstruction.config.navModel")
  get targetUIRoute() {
    return this.router.currentInstruction.config.navModel;
  }
  set targetUIRoute(navModel: NavModel | undefined) {
    if (!navModel?.config.name) {
      return;
    }
    this.router.navigateToRoute(navModel.config.name, navModel.config.settings.params);
  }

  /** Do not show left menu, if there is one or less items */
  @computedFrom("leftMenuCandidates.length")
  get showMenu() {
    return this.leftMenuCandidates.length > 1;
  }

  /** Compute left menu candidates from header + permissions. */
  @computedFrom("router.currentInstruction.config.settings.header")
  get leftMenuCandidates() {
    const header: Header = this.router.currentInstruction.config.settings.header;
    return this.router.navigation.filter(h => h.config.settings.header === header && !h.config.settings.subMenu && h.config.settings.permission(this.api.session));
  }

  subMenu(subMenu: string) {
    return this.router.navigation.filter(h => h.config.settings.subMenu === subMenu);
  }

  /** Take candidates and check if they need sub-headers or not */
  @computedFrom("router.currentInstruction.config.name")
  get leftMenu() {
    let order: string[] = [];
    let headerToRoute: { [key: string]: NavModel[]; } = {};
    this.leftMenuCandidates.forEach(lmc => {
      let key = lmc.config.settings.leftHeader || "";
      if (!order.includes(key)) {
        order.push(key);
        headerToRoute[key] = [];
      }
      headerToRoute[key].push(lmc);
    });
    return order.map(o => {
      return {
        header: o,
        routes: headerToRoute[o],
      };
    });
  }

  // * for mobile, calculate array of routes only for html-select
  @computedFrom("leftMenuCandidates", "i18n.i18next.language")
  get leftMobileMenu(): UINavModel[] {
    let lmc = this.leftMenuCandidates.map(x => ({
      name: this.i18n.tr("app." + x.config.settings.titleKey),
      id: x,
    }));

    if(lmc.find(x => x.id.relativeHref == this.router.currentInstruction.config.route)) {
      return lmc;
    }

    // * add the current route to mobile menu if it's not already there
    let nm = this.router.routes.find(x => x.route == this.router.currentInstruction.config.route)?.navModel;

    if(! nm) {
      return lmc;
    }

    lmc.push({
      name: this.i18n.tr("app." + nm.config.settings.titleKey),
      id: nm,
    });

    return lmc;
  }

  /** Make top main-menu, push first seen action with correct header as the default action for that view */
  @computedFrom("router.navigationInstructions", "api.session", "i18n.i18next.language")
  get headers() {
    let result: Header[] = [];

    let tmp: { [key: string]: { text?: string, icon: string; hiddenOnTablet?: boolean; }; } = {
      news: {
        icon: "fa fa-newspaper",
      },
      units: {
        icon: "fa fa-desktop",
      },
      billing: {
        icon: "fa fa-envelope",
      },
      bookkeeping: {
        icon: "fa fa-folder",
      },
      actors: {
        icon: "fa fa-users",
        hiddenOnTablet: true,
      },
      "gift-cards": {
        icon: "fa fa-gift",
        hiddenOnTablet: true,
      },
      manifests: {
        icon: "fa fa-truck",
        hiddenOnTablet: true,
      },
      eAgreements: {
        icon: "fa fa-bolt",
        hiddenOnTablet: true,
      },
      pos: {
        icon: "fa fa-cash-register",
        hiddenOnTablet: true,
      },
      admin: {
        icon: "fa fa-cogs",
      },
    };
    for (let route of this.router.navigation) {
      if (route.settings.header && tmp[route.settings.header] && route.settings.permission(this.api.session)) {
        let cfg = tmp[route.settings.header];
        result.push({
          header: route.settings.header,
          text: this.i18n.tr("app." + route.settings.header),
          icon: cfg.icon,
          name: route.config.name!,
          settings: route.config.settings,
          hiddenOnTablet: !! cfg.hiddenOnTablet,
        });
        delete tmp[route.settings.header];
      }
    }
    return result;
  }

  configureRouter(config: RouterConfiguration, router: Router) {
    config.fallbackRoute("/");
    config.options.pushState = true;
    config.options.root = "/";
    config.addPipelineStep('authorize', new AuthorizeStep(this.api));
    // ? fonts - e.g. kilroy
    // ? How to do e.g. in news, that news.topic would be in breadcrumbs

    /**
     * permission == do we have rights to access || is it visible in menu
     * header     == ties item to headers.header i.e. the upper main manu
     * titleKey   == key for translation in the actual submenu
     * leftHeader == If we need to group left menu to sections, which section this item belongs to.
     * params     == params to bind
     * notificationKey: keyof SessionNotification
     * icon: if notificationKey in SessionNotification is true, will display this fa-icon in left menu
     * 
     */
    config.map([
      // * news
      {
        route: "news/index", moduleId: PLATFORM.moduleName("news/index"), name: "news/index", nav: true,
        settings: { permission: (session?: Session) => session, header: "news", titleKey: "frontpageText" },
      },
      {
        route: "news/dashboard-update", moduleId: PLATFORM.moduleName("news/dashboard-update"), name: "news/dashboard-update",
        settings: { permission: (session?: Session) => session, header: "news", titleKey: "dashboardUpdate" },
      },
      {
        route: "news/news-article", moduleId: PLATFORM.moduleName("news/news-article"), name: "news/news-article",
        settings: { permission: (session?: Session) => session, header: "news", titleKey: "frontpageText" },
      },
      {
        route: "news/pos-update", moduleId: PLATFORM.moduleName("news/pos-update"), name: "news/pos-update",
        settings: { permission: (session?: Session) => session, header: "news", titleKey: "posUpdate" },
      },

      // * Units
      {
        route: "units/list", moduleId: PLATFORM.moduleName("units/list"), name: "units/list", nav: true,
        settings: { permission: (session?: Session) => session?.actorId, header: "units" },
      },
      {
        route: "units/show", moduleId: PLATFORM.moduleName("units/show"), name: "units/show",
        settings: { permission: (session?: Session) => session?.actorId, header: "units", },
      },

      // * Billing
      {
        route: "billing/incomplete-list", moduleId: PLATFORM.moduleName("billing/incomplete-list"), name: "billing/incomplete-list", nav: true,
        settings: { permission: (session?: Session) => session?.canBookkeepingIntegration, header: "billing", titleKey: "incompleteList", },
      },
      {
        route: "billing/sales-invoice-list", moduleId: PLATFORM.moduleName("billing/sales-invoice-list"), name: "billing/sales-invoice-list", nav: true,
        settings: { permission: (session?: Session) => session?.canBookkeepingIntegration, header: "billing", titleKey: "salesInvoiceList", },
      },
      {
        route: "billing/prepayment-list", moduleId: PLATFORM.moduleName("billing/prepayment-list"), name: "billing/prepayment-list", nav: true,
        settings: { permission: (session?: Session) => session?.canBookkeepingIntegration, header: "billing", titleKey: "prepaymentList", },
      },
      {
        route: "billing/handle", moduleId: PLATFORM.moduleName("billing/handle"), name: "billing/handle",
        settings: { permission: (session?: Session) => session?.canBookkeepingIntegration, header: "billing", titleKey: "billingHandling", },
      },
      {
        route: "billing/combined-incomplete-list", moduleId: PLATFORM.moduleName("billing/combined-incomplete-list"), name: "billing/combined-incomplete-list", nav: true,
        settings: { permission: (session?: Session) => session?.canBookkeepingIntegration, header: "billing", titleKey: "combinedInvoicesIncomplete", },
      },
      {
        route: "billing/combined-preview", moduleId: PLATFORM.moduleName("billing/combined-preview"), name: "billing/combined-preview",
        settings: { permission: (session?: Session) => session?.canBookkeepingIntegration, header: "billing", titleKey: "combinedPreview", },
      },
      {
        route: "billing/combined-list", moduleId: PLATFORM.moduleName("billing/combined-list"), name: "billing/combined-list", nav: true,
        settings: { permission: (session?: Session) => session?.canBookkeepingIntegration, header: "billing", titleKey: "combinedInvoicesComplete", },
      },
      {
        route: "billing/customers/list", moduleId: PLATFORM.moduleName("billing/customers/list"), name: "billing/customers/list", nav: true,
        settings: { permission: (session?: Session) => session?.canBookkeepingIntegration, header: "billing", titleKey: "bookkeepingCustomers", },
      },
      {
        route: "billing/customers/edit", moduleId: PLATFORM.moduleName("billing/customers/edit"), name: "billing/customers/edit",
        settings: { permission: (session?: Session) => session?.canBookkeepingIntegration, header: "billing", titleKey: "bookkeepingCustomers" },
      },

      // * Bookkeeping
      //  * Reports
      {
        route: "bookkeeping/report/audit-memo", moduleId: PLATFORM.moduleName("bookkeeping/report/audit-memo"), name: "bookkeeping/report/audit-memo", nav: true,
        settings: { permission: (session?: Session) => session?.actorId, header: "bookkeeping", titleKey: "auditMemo", leftHeader: "reports" },
      },
      {
        route: "bookkeeping/report/gift-card-debt", moduleId: PLATFORM.moduleName("bookkeeping/report/gift-card-debt"), name: "bookkeeping/report/gift-card-debt", nav: true,
        settings: { permission: (session?: Session) => session?.canGiftCard, header: "bookkeeping", titleKey: "giftCardDebt", leftHeader: "reports" },
      },
      {
        route: "bookkeeping/report/gift-card-usage", moduleId: PLATFORM.moduleName("bookkeeping/report/gift-card-usage"), name: "bookkeeping/report/gift-card-usage", nav: true,
        settings: { permission: (session?: Session) => session?.canGiftCard, header: "bookkeeping", titleKey: "giftCardUsage", leftHeader: "reports" },
      },
      //  * Settings
      {
        route: "bookkeeping/settings/integration-message-list", moduleId: PLATFORM.moduleName("bookkeeping/settings/integration-message-list"), name: "bookkeeping/settings/integration-message-list", nav: true,
        settings: { permission: (session?: Session) => session?.canIntegrationSettings, header: "bookkeeping", titleKey: "integrationMessages", leftHeader: "settings",
        notificationKey: "integrationMessageErrors", icon: "fa fa-exclamation-triangle" },
      },
      {
        route: "bookkeeping/settings/target-list", moduleId: PLATFORM.moduleName("bookkeeping/settings/target-list"), name: "bookkeeping/settings/target-list", nav: true,
        settings: { permission: (session?: Session) => session?.canIntegrationSettings, header: "bookkeeping", titleKey: "targets", leftHeader: "settings" },
      },
      {
        route: "bookkeeping/settings/target-edit", moduleId: PLATFORM.moduleName("bookkeeping/settings/target-edit"), name: "bookkeeping/settings/target-edit",
        settings: { permission: (session?: Session) => session?.canIntegrationSettings, header: "bookkeeping", titleKey: "targets" },
      },
      {
        route: "bookkeeping/settings/client-integration-list", moduleId: PLATFORM.moduleName("bookkeeping/settings/client-integration-list"), name: "bookkeeping/settings/client-integration-list", nav: true,
        settings: { permission: (session?: Session) => session?.canIntegrationSettings, header: "bookkeeping", titleKey: "integrations", leftHeader: "settings" },
      },
      {
        route: "bookkeeping/settings/client-integration-edit", moduleId: PLATFORM.moduleName("bookkeeping/settings/client-integration-edit"), name: "bookkeeping/settings/client-integration-edit",
        settings: { permission: (session?: Session) => session?.canIntegrationSettings, header: "bookkeeping", titleKey: "integrations" },
      },
      {
        route: "bookkeeping/settings/source-list", moduleId: PLATFORM.moduleName("bookkeeping/settings/source-list"), name: "bookkeeping/settings/source-list", nav: true,
        settings: { permission: (session?: Session) => session?.canIntegrationSettings, header: "bookkeeping", titleKey: "sources", leftHeader: "settings" },
      },
      {
        route: "bookkeeping/settings/log-accounting", moduleId: PLATFORM.moduleName("bookkeeping/settings/log-accounting"), name: "bookkeeping/settings/log-accounting", nav: true,
        settings: { permission: (session?: Session) => session?.canBookkeepingIntegration, header: "bookkeeping", titleKey: "accountingLog", },
      },
      {
        route: "bookkeeping/settings/source-edit", moduleId: PLATFORM.moduleName("bookkeeping/settings/source-edit"), name: "bookkeeping/settings/source-edit",
        settings: { permission: (session?: Session) => session?.canIntegrationSettings, header: "bookkeeping", titleKey: "sources" },
      },
      {
        route: "bookkeeping/settings/bookkeeping-customer-diff", moduleId: PLATFORM.moduleName("bookkeeping/settings/bookkeeping-customer-diff"), name: "bookkeeping/settings/bookkeeping-customer-diff",
        settings: { permission: (session?: Session) => session?.canIntegrationSettings, header: "bookkeeping", titleKey: "bookkeepingCustomerDiffs", },
      },

      // * Actors
      // * Admin actors
      {
        route: "actors/admin/list", moduleId: PLATFORM.moduleName("actors/admin/list"), name: "actors/admin/list", nav: true,
        settings: { permission: (session?: Session) => session?.canAdmin, header: "actors", },
      },
      {
        route: "actors/admin/edit", moduleId: PLATFORM.moduleName("actors/admin/edit"), name: "actors/admin/edit",
        settings: { permission: (session?: Session) => session?.canAdmin, header: "actors", },
      },
      // * Non-admin actors
      {
        route: "actors/normal/list", moduleId: PLATFORM.moduleName("actors/normal/list"), name: "actors/normal/list", nav: true,
        settings: { permission: (session?: Session) => !session?.canAdmin && session?.canCreateUsers, header: "actors", },
      },
      {
        route: "actors/normal/edit", moduleId: PLATFORM.moduleName("actors/normal/edit"), name: "actors/normal/edit",
        settings: { permission: (session?: Session) => !session?.canAdmin && session?.canCreateUsers, header: "actors", },
      },

       // * giftCards
      {
        route: "gift-cards/list", moduleId: PLATFORM.moduleName("gift-cards/list"), name: "gift-cards/list", nav: true,
        settings: { permission: (session?: Session) => session?.canGiftCard, titleKey: "gift-cards", header: "gift-cards", },
      },
      {
        route: "gift-cards/show", moduleId: PLATFORM.moduleName("gift-cards/show"), name: "gift-cards/show",
        settings: { permission: (session?: Session) => session?.canGiftCard, titleKey: "giftCard", header: "gift-cards", },
      },
      {
        route: "gift-cards/upload", moduleId: PLATFORM.moduleName("gift-cards/upload"), name: "gift-cards/upload", nav:true,
        settings: { permission: (session?: Session) => session?.canAdmin, titleKey: "giftCardExcelUpload", header: "gift-cards", },
      },
      {
        route: "gift-cards/products/list", moduleId: PLATFORM.moduleName("gift-cards/products/list"), name: "gift-cards/products/list", nav: true,
        settings: { permission: (session?: Session) => session?.canGiftCard, titleKey: "giftCardProducts", header: "gift-cards", },
      },
      {
        route: "gift-cards/products/edit", moduleId: PLATFORM.moduleName("gift-cards/products/edit"), name: "gift-cards/products/edit",
        settings: { permission: (session?: Session) => session?.canGiftCard, titleKey: "giftCardProducts", header: "gift-cards", },
      },
      {
        route: "gift-cards/settings/list", moduleId: PLATFORM.moduleName("gift-cards/settings/list"), name: "gift-cards/settings/list", nav:true,
        settings: { permission: (session?: Session) => session?.canGiftCard, titleKey: "giftCardTemplates", header: "gift-cards" },
      },
      {
        route: "gift-cards/settings/edit", moduleId: PLATFORM.moduleName("gift-cards/settings/edit"), name: "gift-cards/settings/edit",
        settings: { permission: (session?: Session) => session?.canGiftCard, titleKey: "giftCardTemplates", header: "gift-cards" },
      },
      {
        route: "gift-cards/email-templates/list", moduleId: PLATFORM.moduleName("gift-cards/email-templates/list"), name: "gift-cards/email-templates/list", nav: true,
        settings: { permission: (session?: Session) => session?.canGiftCard, titleKey: "emailTemplates", header: "gift-cards" },
      },
      {
        route: "gift-cards/email-templates/edit", moduleId: PLATFORM.moduleName("gift-cards/email-templates/edit"), name: "gift-cards/email-templates/edit",
        settings: { permission: (session?: Session) => session?.canGiftCard, titleKey: "emailTemplates", header: "gift-cards" },
      },
      // * Add report for gift-cards

      // * Agreements for electronic identities
      {
        route: "e-agreements/list", moduleId: PLATFORM.moduleName("e-agreements/list"), name: "e-agreement/list", nav: true,
        settings: { permission: (session?: Session) => session?.canAdmin || session?.canEAgreement, header: "eAgreements", titleKey: "eAgreements" },
      },
      {
        route: "e-agreements/edit", moduleId: PLATFORM.moduleName("e-agreements/edit"), name: "e-agreement/edit",
        settings: { permission: (session?: Session) => session?.canAdmin || session?.canEAgreement, header: "eAgreements", titleKey: "eAgreement" },
      },

      // * Herales
      {
        route: "manifests/list", moduleId: PLATFORM.moduleName("manifests/list"), name: "manifests/list", nav: true,
        settings: { permission: (session?: Session) => session?.canAdmin, header: "manifests", titleKey: "inbound", },
      },
      {
        route: "manifests/edit", moduleId: PLATFORM.moduleName("manifests/edit"), name: "manifests/edit",
        settings: { permission: (session?: Session) => session?.canAdmin, header: "manifests", titleKey: "inbound" },
      },
      {
        route: "manifests/party-link", moduleId: PLATFORM.moduleName("manifests/party-link"), name: "manifests/party-link",
        settings: { permission: (session?: Session) => session?.canAdmin, header: "manifests", titleKey: "inbound", },
      },
      {
        route: "manifests/product-link", moduleId: PLATFORM.moduleName("manifests/product-link"), name: "manifests/product-link",
        settings: { permission: (session?: Session) => session?.canAdmin, header: "manifests", titleKey: "inbound", },
      },
      {
        route: "manifests/product-category", moduleId: PLATFORM.moduleName("manifests/product-category"), name: "manifests/product-category", nav: true,
        settings: { permission: (session?: Session) => session?.canAdmin, header: "manifests", titleKey: "productCategories", },
      },
      {
        route: "manifests/product-list", moduleId: PLATFORM.moduleName("manifests/product-list"), name: "manifests/product-list", nav: true,
        settings: { permission: (session?: Session) => session?.canAdmin, header: "manifests", titleKey: "products", },
      },
      {
        route: "manifests/product-edit", moduleId: PLATFORM.moduleName("manifests/product-edit"), name: "manifests/product-edit",
        settings: { permission: (session?: Session) => session?.canAdmin, header: "manifests", titleKey: "products" },
      },

      // * Tulopos-admin section
      {
        route: "admin/clients/list", moduleId: PLATFORM.moduleName("admin/clients/list"), name: "admin/clients/list", nav: true,
        settings: {
          permission: (session?: Session) => session?.canAdmin, header: "admin", titleKey: "units",
        },
      },
      {
        route: "admin/clients/list-deleted", moduleId: PLATFORM.moduleName("admin/clients/list-deleted"), name: "admin/clients/list-deleted", nav: true,
        settings: {
          permission: (session?: Session) => session?.canBelAdmin, header: "admin", titleKey: "deletedClients",
        },
      },
      {
        route: "admin/clients/new", moduleId: PLATFORM.moduleName("admin/clients/new"), name: "admin/clients/new",
        settings: { permission: (session?: Session) => session?.canAdmin, header: "admin", titleKey: "newUnit", },
      },
      // * clients/show-*
      {
        route: "admin/clients/show-home", moduleId: PLATFORM.moduleName("admin/clients/show-home"), name: "admin/clients/show-home", nav: true,
        settings: { permission: (session?: Session) => session?.canAdmin, header: "admin", titleKey: "units", subMenu: "unit", "subMenuTitleKey": "unitSubMenu.home" },
      },
      {
        route: "admin/clients/show-status", moduleId: PLATFORM.moduleName("admin/clients/show-status"), name: "admin/clients/show-status", nav: true,
        settings: { permission: (session?: Session) => session?.canAdmin, header: "admin", titleKey: "units", subMenu: "unit", "subMenuTitleKey": "unitSubMenu.status" },
      },
      {
        route: "admin/clients/show-edit", moduleId: PLATFORM.moduleName("admin/clients/show-edit"), name: "admin/clients/show-edit", nav: true,
        settings: { permission: (session?: Session) => session?.canAdmin, header: "admin", titleKey: "units", subMenu: "unit", "subMenuTitleKey": "unitSubMenu.edit" },
      },
      
      {
        route: "admin/clients/show-action", moduleId: PLATFORM.moduleName("admin/clients/show-action"), name: "admin/clients/show-action", nav: true,
        settings: { permission: (session?: Session) => session?.canAdmin, header: "admin", titleKey: "units", subMenu: "unit", "subMenuTitleKey": "unitSubMenu.action" },
      },
      {
        route: "admin/clients/show-version", moduleId: PLATFORM.moduleName("admin/clients/show-version"), name: "admin/clients/show-version", nav: true,
        settings: { permission: (session?: Session) => session?.canAdmin, header: "admin", titleKey: "units", subMenu: "unit", "subMenuTitleKey": "unitSubMenu.version" },
      },
      {
        route: "admin/clients/show-device", moduleId: PLATFORM.moduleName("admin/clients/show-device"), name: "admin/clients/show-device", nav: true,
        settings: { permission: (session?: Session) => session?.canAdmin, header: "admin", titleKey: "units", subMenu: "unit", "subMenuTitleKey": "unitSubMenu.device" },
      },
      // * clients/show-* end
      {
        route: "admin/clients/update-tool", moduleId: PLATFORM.moduleName("admin/clients/update-tool"), name: "admin/clients/update-tool", nav: true,
        settings: { permission: (session?: Session) => session?.canSuperuser, header: "admin", titleKey: "updateTool"},
      },
      {
        route: "admin/business-groups/list", moduleId: PLATFORM.moduleName("admin/business-groups/list"), name: "admin/business-groups/list", nav: true,
        settings: { permission: (session?: Session) => session?.canAdmin, header: "admin", titleKey: "businessGroups", },
      },
      {
        route: "admin/business-groups/edit", moduleId: PLATFORM.moduleName("admin/business-groups/edit"), name: "admin/business-groups/edit",
        settings: { permission: (session?: Session) => session?.canAdmin, header: "admin", titleKey: "businessGroups", },
      },
      {
        route: "admin/corporations/list", moduleId: PLATFORM.moduleName("admin/corporations/list"), name: "admin/corporations/list", nav: true,
        settings: { permission: (session?: Session) => session?.canAdmin, header: "admin", titleKey: "corporations", },
      },
      {
        route: "admin/corporations/edit", moduleId: PLATFORM.moduleName("admin/corporations/edit"), name: "admin/corporations/edit",
        settings: { permission: (session?: Session) => session?.canAdmin, header: "admin", titleKey: "corporations", },
      },
      {
        route: "admin/news/urgent/list", moduleId: PLATFORM.moduleName("admin/news/list"), name: "admin/news/urgent/list", activationStrategy: 'replace', nav: true,
        settings: { permission: (session?: Session) => session?.canAdmin, header: "admin", titleKey: "alerts", params: { type: 'ALERT', }, },
      },
      {
        route: "admin/news/list", moduleId: PLATFORM.moduleName("admin/news/list"), name: "admin/news/list", activationStrategy: 'replace', nav: true,
        settings: { permission: (session?: Session) => session?.canAdmin, header: "admin", titleKey: "news", params: { type: 'NEWS', }, },
      },
      {
        route: "admin/news/edit", moduleId: PLATFORM.moduleName("admin/news/edit"), name: "admin/news/edit",
        settings: { permission: (session?: Session) => session?.canAdmin, header: "admin", titleKey: "news", },
      },
      {
        route: "admin/news/urgent/edit", moduleId: PLATFORM.moduleName("admin/news/edit"), name: "admin/news/urgent/edit",
        settings: { permission: (session?: Session) => session?.canAdmin, header: "admin", titleKey: "alerts", },
      },
      {
        route: "admin/repository/list", moduleId: PLATFORM.moduleName("admin/repository/list"), name: "admin/repository/list", nav: true,
        settings: { permission: (session?: Session) => session?.canAdmin, header: "admin", titleKey: "repository", },
      },
      {
        route: "admin/repository/edit", moduleId: PLATFORM.moduleName("admin/repository/edit"), name: "admin/repository/edit",
        settings: { permission: (session?: Session) => session?.canAdmin, header: "admin", titleKey: "repository", },
      },
      {
        route: "admin/dashboard/list", moduleId: PLATFORM.moduleName("admin/release-log/list"), name: "admin/dashboard/list", nav: true,
        settings: { permission: (session?: Session) => session?.canAdmin, header: "admin", titleKey: "dashboard", type: "DASHBOARD" },
      },
      {
        route: "admin/nettikauppa/list", moduleId: PLATFORM.moduleName("admin/release-log/list"), name: "admin/nettikauppa/list", nav: true,
        settings: { permission: (session?: Session) => session?.canAdmin, header: "admin", titleKey: "nettikauppa", type: "NETSTORE" },
      },
      {
        route: "admin/release-log/edit", moduleId: PLATFORM.moduleName("admin/release-log/edit"), name: "admin/release-log/edit",
        settings: { permission: (session?: Session) => session?.canAdmin, header: "admin", titleKey: "release-log", },
      },
      {
        route: "admin/help/list-html", moduleId: PLATFORM.moduleName("admin/help/list-html"), name: "admin/help/list-html", activationStrategy: 'replace', nav: true,
        settings: { permission: (session?: Session) => session?.canAdmin, header: "admin", titleKey: "helpPos", params: { type: 'POS', },},
      },
      {
        route: "admin/help/dashboard-list-html", moduleId: PLATFORM.moduleName("admin/help/list-html"), name: "admin/help/dashboard-list-html", activationStrategy: 'replace', nav: true,
        settings: { permission: (session?: Session) => session?.canAdmin, header: "admin", titleKey: "helpDashboard", params: { type: 'DASHBOARD', }, },
      },
      {
        route: "admin/help/webstore-list-html", moduleId: PLATFORM.moduleName("admin/help/list-html"), name: "admin/help/webstore-list-html", activationStrategy: 'replace', nav: true,
        settings: { permission: (session?: Session) => session?.canAdmin, header: "admin", titleKey: "helpNetStore", params: { type: 'NETSTORE', }, },
      },
      {
        route: "admin/help/edit-html", moduleId: PLATFORM.moduleName("admin/help/edit-html"), name: "admin/help/edit-html",
        settings: { permission: (session?: Session) => session?.canAdmin, header: "admin", titleKey: "helpPos", },
      },
      {
        route: "admin/help/dashboard-edit-html", moduleId: PLATFORM.moduleName("admin/help/edit-html"), name: "admin/help/dashboard-edit-html",
        settings: { permission: (session?: Session) => session?.canAdmin, header: "admin", titleKey: "helpDashboard", },
      },
      {
        route: "admin/help/webstore-edit-html", moduleId: PLATFORM.moduleName("admin/help/edit-html"), name: "admin/help/webstore-edit-html",
        settings: { permission: (session?: Session) => session?.canAdmin, header: "admin", titleKey: "helpNetStore", },
      },
      {
        route: "admin/integration-error-message-mapper/list", moduleId: PLATFORM.moduleName("admin/integration-error-message-mapper/list"), name: "admin/integration-error-message-mapper/list", nav: true,
        settings: { permission: (session?: Session) => session?.canAdmin, header: "admin", titleKey: "integrationErrorMessageMapper", },
      },
      {
        route: "admin/integration-error-message-mapper/edit", moduleId: PLATFORM.moduleName("admin/integration-error-message-mapper/edit"), name: "admin/integration-error-message-mapper/edit",
        settings: { permission: (session?: Session) => session?.canAdmin, header: "admin", titleKey: "integrationErrorMessageMapper", },
      },
      {
        route: "admin/integration/source-accounting", moduleId: PLATFORM.moduleName("admin/integration/source-accounting"), name: "admin/integration/source-accounting", nav: true,
        settings: { permission: (session?: Session) => session?.canAdmin, header: "admin", titleKey: "manualAccountings", },
      },
      {
        route: "admin/integration/source-billing", moduleId: PLATFORM.moduleName("admin/integration/source-billing"), name: "admin/integration/source-billing", nav: true,
        settings: { permission: (session?: Session) => session?.canAdmin, header: "admin", titleKey: "manualBilling", },
      },
      {
        route: "admin/integration/target-accounting", moduleId: PLATFORM.moduleName("admin/integration/target-accounting"), name: "admin/integration/target-accounting", nav: true,
        settings: { permission: (session?: Session) => session?.canAdmin, header: "admin", titleKey: "accountingsToBookkeeping", },
      },
      {
        route: "admin/integration/target-prepayment", moduleId: PLATFORM.moduleName("admin/integration/target-prepayment"), name: "admin/integration/target-prepayment", nav: true,
        settings: { permission: (session?: Session) => session?.canAdmin, header: "admin", titleKey: "prepaymentsManual", },
      },
      {
        route: "admin/integration/target-billing", moduleId: PLATFORM.moduleName("admin/integration/target-billing"), name: "admin/integration/target-billing", nav: true,
        settings: { permission: (session?: Session) => session?.canAdmin, header: "admin", titleKey: "salesInvoicesManual", },
      },
      {
        route: "admin/email-templates/list", moduleId: PLATFORM.moduleName("admin/email-templates/list"), name: "admin/email-templates/list", nav: true,
        settings: { permission: (session?: Session) => session?.canAdmin, header: "admin", titleKey: "emailTemplates", },
      },
      {
        route: "admin/email-templates/edit", moduleId: PLATFORM.moduleName("admin/email-templates/edit"), name: "admin/email-templates/edit",
        settings: { permission: (session?: Session) => session?.canAdmin, header: "admin", titleKey: "emailTemplates", },
      },
      {
        route: "admin/report/unit-list", moduleId: PLATFORM.moduleName("admin/report/unit-list"), name: "admin/report/unit-list", nav: true,
        settings: { permission: (session?: Session) => session?.canSuperuser, header: "admin", titleKey: "rsUnitreportList", },
      },
      {
        route: "admin/report/unit-show", moduleId: PLATFORM.moduleName("admin/report/unit-show"), name: "admin/report/unit-show",
        settings: { permission: (session?: Session) => session?.canSuperuser, header: "admin", titleKey: "rsUnitreportList", },
      },
      {
        route: "admin/report/isv-report", moduleId: PLATFORM.moduleName("admin/report/isv-report"), name: "admin/report/isv-report", nav: true,
        settings: { permission: (session?: Session) => session?.canSuperuser, header: "admin", titleKey: "isvReport", },
      },
      {
        route: "admin/servers/list", moduleId: PLATFORM.moduleName("admin/servers/list"), name: "admin/servers/list", nav: true,
        settings: { permission: (session?: Session) => session?.canBelAdmin, header: "admin", titleKey: "belServers", },
      },
      {
        route: "admin/servers/edit", moduleId: PLATFORM.moduleName("admin/servers/edit"), name: "admin/servers/edit",
        settings: { permission: (session?: Session) => session?.canBelAdmin, header: "admin", titleKey: "belServers", }
      },
      {
        route: "admin/payment-terminals/list", moduleId: PLATFORM.moduleName("admin/payment-terminals/list"), name: "admin/payment-terminals/list", nav: true,
        settings: { permission: (session?: Session) => session?.canAdmin, header: "admin", titleKey: "paymentTerminals", },
      },
      {
        route: "admin/payment-terminals/edit", moduleId: PLATFORM.moduleName("admin/payment-terminals/edit"), name: "admin/payment-terminals/edit",
        settings: { permission: (session?: Session) => session?.canAdmin, header: "admin", titleKey: "paymentTerminals", },
      },
      {
        route: "admin/payment-terminals/manufacturer-list", moduleId: PLATFORM.moduleName("admin/payment-terminals/manufacturer-list"), name: "admin/payment-terminals/manufacturer-list",
        settings: { permission: (session?: Session) => session?.canAdmin, header: "admin", titleKey: "paymentTerminals", },
      },
      {
        route: "admin/payment-terminals/manufacturer-edit", moduleId: PLATFORM.moduleName("admin/payment-terminals/manufacturer-edit"), name: "admin/payment-terminals/manufacturer-edit",
        settings: { permission: (session?: Session) => session?.canAdmin, header: "admin", titleKey: "paymentTerminals", },
      },
      {
        route: "admin/payment-terminals/model-list", moduleId: PLATFORM.moduleName("admin/payment-terminals/model-list"), name: "admin/payment-terminals/model-list",
        settings: { permission: (session?: Session) => session?.canAdmin, header: "admin", titleKey: "paymentTerminals", },
      },
      {
        route: "admin/payment-terminals/model-edit", moduleId: PLATFORM.moduleName("admin/payment-terminals/model-edit"), name: "admin/payment-terminals/model-edit",
        settings: { permission: (session?: Session) => session?.canAdmin, header: "admin", titleKey: "paymentTerminals", },
      },
      {
        route: "admin/payment-terminals/site-list", moduleId: PLATFORM.moduleName("admin/payment-terminals/site-list"), name: "admin/payment-terminals/site-list",
        settings: { permission: (session?: Session) => session?.canAdmin, header: "admin", titleKey: "paymentTerminals", },
      },
      {
        route: "admin/payment-terminals/site-edit", moduleId: PLATFORM.moduleName("admin/payment-terminals/site-edit"), name: "admin/payment-terminals/site-edit",
        settings: { permission: (session?: Session) => session?.canAdmin, header: "admin", titleKey: "paymentTerminals", },
      },
      {
        route: "admin/payment-terminals/status-list", moduleId: PLATFORM.moduleName("admin/payment-terminals/status-list"), name: "admin/payment-terminals/status-list",
        settings: { permission: (session?: Session) => session?.canAdmin, header: "admin", titleKey: "paymentTerminals", },
      },
      {
        route: "admin/payment-terminals/status-edit", moduleId: PLATFORM.moduleName("admin/payment-terminals/status-edit"), name: "admin/payment-terminals/status-edit",
        settings: { permission: (session?: Session) => session?.canAdmin, header: "admin", titleKey: "paymentTerminals", },
      },
      {
        route: "admin/viva-wallet-isv-credentials/edit", moduleId: PLATFORM.moduleName("admin/viva-wallet-isv-credentials/edit"), name: "admin/viva-wallet-isv-credentials/edit", nav: true,
        settings: { permission: (session?: Session) => session?.canSuperuser, header: "admin", titleKey: "vivaWalletIsvCredentials", },
      },
      {
        route: "admin/scheduler/status", moduleId: PLATFORM.moduleName("admin/scheduler/status"), name: "admin/scheduler/status", nav: true,
        settings: { permission: (session?: Session) => session?.canAdmin, header: "admin", titleKey: "schedulerStatus", },
      },
      // * Admin - Translations
      {
        route: "admin/translations/default", moduleId: PLATFORM.moduleName("admin/translations/default"), name: "admin/translations/default", nav: true,
        settings: { permission: (session?: Session) => session?.canBelAdmin, header: "admin", titleKey: "defaultTranslations", leftHeader: "translations" },
      },
      {
        route: "admin/translations/list", moduleId: PLATFORM.moduleName("admin/translations/list"), name: "admin/translations/list", nav: true,
        settings: { permission: (session?: Session) => session?.canAdmin, header: "admin", titleKey: "editTranslations", leftHeader: "translations" },
      },
      {
        route: "admin/translations/edit", moduleId: PLATFORM.moduleName("admin/translations/edit"), name: "admin/translations/edit",
        settings: { permission: (session?: Session) => session?.canAdmin, header: "admin", titleKey: "editTranslations", leftHeader: "translations" },
      },
      // * Admin - POS settings
      {
        route: "admin/pos/portion-size-list", moduleId: PLATFORM.moduleName("pos/portion-size-list"), name: "admin/pos/portion-size-list", activationStrategy: 'replace', nav: true,
        settings: { permission: (session?: Session) => session?.canAdmin, header: "admin", titleKey: "posPortionSize", leftHeader: "posSettings", params: { isMaster: true }},
      },
      {
        route: "admin/pos/portion-size-edit", moduleId: PLATFORM.moduleName("pos/portion-size-edit"), name: "admin/pos/portion-size-edit",
        settings: { permission: (session?: Session) => session?.canAdmin, header: "admin", titleKey: "posPortionSize", leftHeader: "posSettings" },
      },
      {
        route: "admin/pos/vat-category-list", moduleId: PLATFORM.moduleName("pos/vat-category-list"), name: "admin/pos/vat-category-list", nav: true,
        settings: { permission: (session?: Session) => session?.canAdmin, header: "admin", titleKey: "posVatCategory", leftHeader: "posSettings",},
      },
      {
        route: "admin/pos/vat-category-edit", moduleId: PLATFORM.moduleName("pos/vat-category-edit"), name: "admin/pos/vat-category-edit",
        settings: { permission: (session?: Session) => session?.canAdmin, header: "admin", titleKey: "posVatCategory", leftHeader: "posSettings" },
      },
      {
        route: "admin/pos/product-category-list", moduleId: PLATFORM.moduleName("pos/product-category-list"), name: "admin/pos/product-category-list", activationStrategy: 'replace', nav: true,
        settings: { permission: (session?: Session) => session?.canAdmin, header: "admin", titleKey: "posProductCategory", leftHeader: "posSettings", params: { isMaster: true } },
      },
      {
        route: "admin/pos/product-category-edit", moduleId: PLATFORM.moduleName("pos/product-category-edit"), name: "admin/pos/product-category-edit",
        settings: { permission: (session?: Session) => session?.canAdmin, header: "admin", titleKey: "posProductCategory", leftHeader: "posSettings" },
      },
      {
        route: "admin/pos/product-sub-category-list", moduleId: PLATFORM.moduleName("pos/product-sub-category-list"), name: "admin/pos/product-sub-category-list", activationStrategy: 'replace', nav: true,
        settings: { permission: (session?: Session) => session?.canAdmin, header: "admin", titleKey: "posProductSubCategory", leftHeader: "posSettings", params: { isMaster: true } },
      },
      {
        route: "admin/pos/product-sub-category-edit", moduleId: PLATFORM.moduleName("pos/product-sub-category-edit"), name: "admin/pos/product-sub-category-edit",
        settings: { permission: (session?: Session) => session?.canAdmin, header: "admin", titleKey: "posProductSubCategory", leftHeader: "posSettings" },
      },
      {
        route: "admin/pos/sales-channel-list", moduleId: PLATFORM.moduleName("pos/sales-channel-list"), name: "admin/pos/sales-channel-list", activationStrategy: 'replace', nav: true,
        settings: { permission: (session?: Session) => session?.canAdmin, header: "admin", titleKey: "posSalesChannel", leftHeader: "posSettings", params: { isMaster: true }},
      },
      {
        route: "admin/pos/sales-channel-edit", moduleId: PLATFORM.moduleName("pos/sales-channel-edit"), name: "admin/pos/sales-channel-edit",
        settings: { permission: (session?: Session) => session?.canAdmin, header: "admin", titleKey: "posSalesChannel", leftHeader: "posSettings" },
      },
      {
        route: "admin/pos/sales-restriction-list", moduleId: PLATFORM.moduleName("pos/sales-restriction-list"), name: "admin/pos/sales-restriction-list", activationStrategy: 'replace', nav: true,
        settings: { permission: (session?: Session) => session?.canAdmin, header: "admin", titleKey: "posSalesRestriction", leftHeader: "posSettings", params: { isMaster: true }},
      },
      {
        route: "admin/pos/sales-restriction-edit", moduleId: PLATFORM.moduleName("pos/sales-restriction-edit"), name: "admin/pos/sales-restriction-edit",
        settings: { permission: (session?: Session) => session?.canAdmin, header: "admin", titleKey: "posSalesRestriction", leftHeader: "posSettings" },
      },
      {
        route: "admin/pos/storage-unit-list", moduleId: PLATFORM.moduleName("pos/storage-unit-list"), name: "admin/pos/storage-unit-list", activationStrategy: 'replace', nav: true,
        settings: { permission: (session?: Session) => session?.canAdmin, header: "admin", titleKey: "posStorageUnit", leftHeader: "posSettings", params: { isMaster: true } },
      },
      {
        route: "admin/pos/storage-unit-edit", moduleId: PLATFORM.moduleName("pos/storage-unit-edit"), name: "admin/pos/storage-unit-edit",
        settings: { permission: (session?: Session) => session?.canAdmin, header: "admin", titleKey: "posStorageUnit", leftHeader: "posSettings" },
      },
      {
        route: "admin/pos/pricing-group-list", moduleId: PLATFORM.moduleName("pos/pricing-group-list"), name: "admin/pos/pricing-group-list", activationStrategy: 'replace', nav: true,
        settings: { permission: (session?: Session) => session?.canAdmin, header: "admin", titleKey: "posPricingGroup", leftHeader: "posSettings", params: { isMaster: true } },
      },
      {
        route: "admin/pos/pricing-group-edit", moduleId: PLATFORM.moduleName("pos/pricing-group-edit"), name: "admin/pos/pricing-group-edit",
        settings: { permission: (session?: Session) => session?.canAdmin, header: "admin", titleKey: "posPricingGroup", leftHeader: "posSettings" },
      },
      {
        route: "admin/pos/viva-wallet-fee-pos", moduleId: PLATFORM.moduleName("pos/viva-wallet-fee-pos-edit"), name: "admin/pos/viva-wallet-fee-pos", activationStrategy: 'replace', nav: true,
        settings: { permission: (session?: Session) => session?.canAdmin, header: "admin", titleKey: "posVivaWalletFee", leftHeader: "posSettings", params: { isMaster: true } },
      },
      {
        route: "admin/pos/viva-wallet-fee-nk-pos", moduleId: PLATFORM.moduleName("pos/viva-wallet-fee-nk-pos-edit"), name: "admin/pos/viva-wallet-fee-nk-pos", activationStrategy: 'replace', nav: true,
        settings: { permission: (session?: Session) => session?.canAdmin, header: "admin", titleKey: "posVivaWalletFeeNkPos", leftHeader: "posSettings", params: { isMaster: true } },
      },
      {
        route: "admin/pos/viva-wallet-fee-nk-giftcard", moduleId: PLATFORM.moduleName("pos/viva-wallet-fee-nk-giftcard-edit"), name: "admin/pos/viva-wallet-fee-nk-giftcard", activationStrategy: 'replace', nav: true,
        settings: { permission: (session?: Session) => session?.canAdmin, header: "admin", titleKey: "posVivaWalletFeeNkGiftCard", leftHeader: "posSettings", params: { isMaster: true } },
      },
      // * Admin - Pos master item copy
      {
        route: "admin/master-item/portion-size", moduleId: PLATFORM.moduleName("admin/master-item/portion-size"), name: "admin/master-item/portion-size", nav: true,
        settings: { permission: (session?: Session) => session?.canAdmin, header: "admin", titleKey: "posPortionSize", leftHeader: "masterItem" },
      },
      {
        route: "admin/master-item/product-category", moduleId: PLATFORM.moduleName("admin/master-item/product-category"), name: "admin/master-item/product-category", nav: true,
        settings: { permission: (session?: Session) => session?.canAdmin, header: "admin", titleKey: "posProductCategory", leftHeader: "masterItem" },
      },
      {
        route: "admin/master-item/product-sub-category", moduleId: PLATFORM.moduleName("admin/master-item/product-sub-category"), name: "admin/master-item/product-sub-category", nav: true,
        settings: { permission: (session?: Session) => session?.canAdmin, header: "admin", titleKey: "posProductSubCategory", leftHeader: "masterItem" },
      },
      {
        route: "admin/master-item/sales-channel", moduleId: PLATFORM.moduleName("admin/master-item/sales-channel"), name: "admin/master-item/sales-channel", nav: true,
        settings: { permission: (session?: Session) => session?.canAdmin, header: "admin", titleKey: "posSalesChannel", leftHeader: "masterItem" },
      },
      {
        route: "admin/master-item/sales-restriction", moduleId: PLATFORM.moduleName("admin/master-item/sales-restriction"), name: "admin/master-item/sales-restriction", nav: true,
        settings: { permission: (session?: Session) => session?.canAdmin, header: "admin", titleKey: "posSalesRestriction", leftHeader: "masterItem" },
      },
      {
        route: "admin/master-item/storage-unit", moduleId: PLATFORM.moduleName("admin/master-item/storage-unit"), name: "admin/master-item/storage-unit", nav: true,
        settings: { permission: (session?: Session) => session?.canAdmin, header: "admin", titleKey: "posStorageUnit", leftHeader: "masterItem" },
      },
      {
        route: "admin/master-item/pricing-group", moduleId: PLATFORM.moduleName("admin/master-item/pricing-group"), name: "admin/master-item/pricing-group", nav: true,
        settings: { permission: (session?: Session) => session?.canAdmin, header: "admin", titleKey: "posPricingGroup", leftHeader: "masterItem" },
      },
      // * pos - these are actually symlinks to admin side for now
      {
        route: "pos/product-list", moduleId: PLATFORM.moduleName("pos/product-list"), name: "pos/product-list", activationStrategy: 'replace', nav: true,
        settings: { permission: (session?: Session) => session?.canAdmin || session?.canPosItemAdmin, header: "pos", titleKey: "posProduct", leftHeader: "posSettings", params: { productType: 'REGULAR', } },
      },
      {
        route: "pos/product-edit", moduleId: PLATFORM.moduleName("pos/product-edit"), name: "pos/product-edit", activationStrategy: 'replace',
        settings: { permission: (session?: Session) => session?.canAdmin || session?.canPosItemAdmin, header: "pos", titleKey: "posProduct", leftHeader: "posSettings" },
      },
      {
        route: "pos/alias-list", moduleId: PLATFORM.moduleName("pos/product-list"), name: "pos/alias-list", activationStrategy: 'replace', nav: true,
        settings: { permission: (session?: Session) => session?.canAdmin || session?.canPosItemAdmin, header: "pos", titleKey: "posAlias", leftHeader: "posSettings", params: { productType: 'ALIAS', } },
      },
      {
        route: "pos/alias-edit", moduleId: PLATFORM.moduleName("pos/alias-edit"), name: "pos/alias-edit", activationStrategy: 'replace',
        settings: { permission: (session?: Session) => session?.canAdmin || session?.canPosItemAdmin, header: "pos", titleKey: "posAlias", leftHeader: "posSettings" },
      },
      {
        route: "pos/recipe-list", moduleId: PLATFORM.moduleName("pos/product-list"), name: "pos/recipe-list", activationStrategy: 'replace', nav: true,
        settings: { permission: (session?: Session) => session?.canAdmin || session?.canPosItemAdmin, header: "pos", titleKey: "posRecipe", leftHeader: "posSettings", params: { productType: 'RECIPE', } },
      },
      {
        route: "pos/recipe-edit", moduleId: PLATFORM.moduleName("pos/recipe-edit"), name: "pos/recipe-edit", activationStrategy: 'replace',
        settings: { permission: (session?: Session) => session?.canAdmin || session?.canPosItemAdmin, header: "pos", titleKey: "posRecipe", leftHeader: "posSettings" },
      },
      {
        route: "admin/pos/discount-groups-list", moduleId: PLATFORM.moduleName("pos/discount-groups-list"), name: "admin/pos/discount-groups-list", activationStrategy: 'replace', nav: true,
        settings: { permission: (session?: Session) => session?.canAdmin || session?.canPosItemAdmin, header: "pos", titleKey: "posDiscountGroup", leftHeader: "posSettings"},
      },
      {
        route: "admin/pos/discount-groups-edit", moduleId: PLATFORM.moduleName("pos/discount-groups-edit"), name: "admin/pos/discount-groups-edit",
        settings: { permission: (session?: Session) => session?.canAdmin || session?.canPosItemAdmin, header: "pos", titleKey: "posDiscountGroup", leftHeader: "posSettings" },
      },
      {
        route: "pos/portion-size-list", moduleId: PLATFORM.moduleName("pos/portion-size-list"), name: "pos/portion-size-list", activationStrategy: 'replace', nav: true,
        settings: { permission: (session?: Session) => session?.canAdmin || session?.canPosItemAdmin, header: "pos", titleKey: "posPortionSize", leftHeader: "posSettings" },
      },
      {
        route: "pos/portion-size-edit", moduleId: PLATFORM.moduleName("pos/portion-size-edit"), name: "pos/portion-size-edit",
        settings: { permission: (session?: Session) => session?.canAdmin || session?.canPosItemAdmin, header: "pos", titleKey: "posPortionSize", leftHeader: "posSettings" },
      },
      {
        route: "pos/product-category-list", moduleId: PLATFORM.moduleName("pos/product-category-list"), name: "pos/product-category-list", activationStrategy: 'replace', nav: true,
        settings: { permission: (session?: Session) => session?.canAdmin || session?.canPosItemAdmin, header: "pos", titleKey: "posProductCategory", leftHeader: "posSettings" },
      },
      {
        route: "pos/product-category-edit", moduleId: PLATFORM.moduleName("pos/product-category-edit"), name: "pos/product-category-edit",
        settings: { permission: (session?: Session) => session?.canAdmin || session?.canPosItemAdmin, header: "pos", titleKey: "posProductCategory", leftHeader: "posSettings" },
      },
      {
        route: "pos/product-sub-category-list", moduleId: PLATFORM.moduleName("pos/product-sub-category-list"), name: "pos/product-sub-category-list", activationStrategy: 'replace', nav: true,
        settings: { permission: (session?: Session) => session?.canAdmin || session?.canPosItemAdmin, header: "pos", titleKey: "posProductSubCategory", leftHeader: "posSettings" },
      },
      {
        route: "pos/product-sub-category-edit", moduleId: PLATFORM.moduleName("pos/product-sub-category-edit"), name: "pos/product-sub-category-edit",
        settings: { permission: (session?: Session) => session?.canAdmin || session?.canPosItemAdmin, header: "pos", titleKey: "posProductSubCategory", leftHeader: "posSettings" },
      },
      {
        route: "pos/sales-channel-list", moduleId: PLATFORM.moduleName("pos/sales-channel-list"), name: "pos/sales-channel-list", activationStrategy: 'replace', nav: true,
        settings: { permission: (session?: Session) => session?.canAdmin || session?.canPosItemAdmin, header: "pos", titleKey: "posSalesChannel", leftHeader: "posSettings"  },
      },
      {
        route: "pos/sales-channel-edit", moduleId: PLATFORM.moduleName("pos/sales-channel-edit"), name: "pos/sales-channel-edit",
        settings: { permission: (session?: Session) => session?.canAdmin || session?.canPosItemAdmin, header: "pos", titleKey: "posSalesChannel", leftHeader: "posSettings" },
      },
      {
        route: "pos/sales-restriction-list", moduleId: PLATFORM.moduleName("pos/sales-restriction-list"), name: "pos/sales-restriction-list", activationStrategy: 'replace', nav: true,
        settings: { permission: (session?: Session) => session?.canAdmin || session?.canPosItemAdmin, header: "pos", titleKey: "posSalesRestriction", leftHeader: "posSettings"  },
      },
      {
        route: "pos/sales-restriction-edit", moduleId: PLATFORM.moduleName("pos/sales-restriction-edit"), name: "pos/sales-restriction-edit",
        settings: { permission: (session?: Session) => session?.canAdmin || session?.canPosItemAdmin, header: "pos", titleKey: "posSalesRestriction", leftHeader: "posSettings" },
      },
      {
        route: "pos/storage-unit-list", moduleId: PLATFORM.moduleName("pos/storage-unit-list"), name: "pos/storage-unit-list", activationStrategy: 'replace', nav: true,
        settings: { permission: (session?: Session) => session?.canAdmin || session?.canPosItemAdmin, header: "pos", titleKey: "posStorageUnit", leftHeader: "posSettings" },
      },
      {
        route: "pos/storage-unit-edit", moduleId: PLATFORM.moduleName("pos/storage-unit-edit"), name: "pos/storage-unit-edit",
        settings: { permission: (session?: Session) => session?.canAdmin || session?.canPosItemAdmin, header: "pos", titleKey: "posStorageUnit", leftHeader: "posSettings" },
      },
      {
        route: "pos/pricing-group-list", moduleId: PLATFORM.moduleName("pos/pricing-group-list"), name: "pos/pricing-group-list", activationStrategy: 'replace', nav: true,
        settings: { permission: (session?: Session) => session?.canAdmin || session?.canPosItemAdmin, header: "pos", titleKey: "posPricingGroup", leftHeader: "posSettings" },
      },
      {
        route: "pos/pricing-group-edit", moduleId: PLATFORM.moduleName("pos/pricing-group-edit"), name: "pos/pricing-group-edit",
        settings: { permission: (session?: Session) => session?.canAdmin || session?.canPosItemAdmin, header: "pos", titleKey: "posPricingGroup", leftHeader: "posSettings" },
      },
      {
        route: "pos/order-list-list", moduleId: PLATFORM.moduleName("pos/order-list-list"), name: "pos/order-list-list", activationStrategy: 'replace', nav: true,
        settings: { permission: (session?: Session) => session?.canAdmin || session?.canPosItemAdmin, header: "pos", titleKey: "posOrderList", leftHeader: "posSettings" },
      },
      {
        route: "pos/order-list-edit", moduleId: PLATFORM.moduleName("pos/order-list-edit"), name: "pos/order-list-edit", activationStrategy: 'replace',
        settings: { permission: (session?: Session) => session?.canAdmin || session?.canPosItemAdmin, header: "pos", titleKey: "posOrderList", leftHeader: "posSettings" },
      },
      {
        route: "pos/order-message-list", moduleId: PLATFORM.moduleName("pos/order-message-list"), name: "pos/order-message-list", activationStrategy: 'replace', nav: true,
        settings: { permission: (session?: Session) => session?.canAdmin || session?.canPosItemAdmin, header: "pos", titleKey: "posOrderMessage", leftHeader: "posSettings" },
      },
      {
        route: "pos/location-list", moduleId: PLATFORM.moduleName("pos/location-list"), name: "pos/location-list", activationStrategy: 'replace', nav: true,
        settings: { permission: (session?: Session) => session?.canAdmin || session?.canPosItemAdmin, header: "pos", titleKey: "posLocation", leftHeader: "posSettings" },
      },
      {
        route: "pos/location-edit", moduleId: PLATFORM.moduleName("pos/location-edit"), name: "pos/location-edit",
        settings: { permission: (session?: Session) => session?.canAdmin || session?.canPosItemAdmin, header: "pos", titleKey: "posLocation", leftHeader: "posSettings" },
      },
      {
        route: "pos/area-list", moduleId: PLATFORM.moduleName("pos/area-list"), name: "pos/area-list", activationStrategy: 'replace', nav: true,
        settings: { permission: (session?: Session) => session?.canAdmin || session?.canPosItemAdmin, header: "pos", titleKey: "posArea", leftHeader: "posSettings" },
      },
      {
        route: "pos/area-edit", moduleId: PLATFORM.moduleName("pos/area-edit"), name: "pos/area-edit",
        settings: { permission: (session?: Session) => session?.canAdmin || session?.canPosItemAdmin, header: "pos", titleKey: "posArea", leftHeader: "posSettings" },
      },
      {
        route: "pos/station-list", moduleId: PLATFORM.moduleName("pos/station-list"), name: "pos/station-list", activationStrategy: 'replace', nav: true,
        settings: { permission: (session?: Session) => session?.canAdmin || session?.canPosItemAdmin, header: "pos", titleKey: "posStation", leftHeader: "posSettings" },
      },
      {
        route: "pos/station-edit", moduleId: PLATFORM.moduleName("pos/station-edit"), name: "pos/station-edit",
        settings: { permission: (session?: Session) => session?.canAdmin || session?.canPosItemAdmin , header: "pos", titleKey: "posStation", leftHeader: "posSettings" },
      },
      {
        route: "pos/payment-terminal-client-list", moduleId: PLATFORM.moduleName("pos/payment-terminal-client-list"), name: "pos/payment-terminal-client-list", activationStrategy: 'replace', nav: true,
        settings: { permission: (session?: Session) => session?.canAdmin || session?.canPosItemAdmin , header: "pos", titleKey: "posPaymentTerminal", leftHeader: "posSettings" },
      },
      {
        route: "pos/payment-terminal-client-edit", moduleId: PLATFORM.moduleName("pos/payment-terminal-client-edit"), name: "pos/payment-terminal-client-edit",
        settings: { permission: (session?: Session) => session?.canAdmin || session?.canPosItemAdmin , header: "pos", titleKey: "posPaymentTerminal", leftHeader: "posSettings" },
      },
      {
        route: "pos/printer-list", moduleId: PLATFORM.moduleName("pos/printer-list"), name: "pos/printer-list", activationStrategy: 'replace', nav: true,
        settings: { permission: (session?: Session) => session?.canAdmin || session?.canPosItemAdmin , header: "pos", titleKey: "posPrinter", leftHeader: "posSettings" },
      },
      {
        route: "pos/printer-edit", moduleId: PLATFORM.moduleName("pos/printer-edit"), name: "pos/printer-edit",
        settings: { permission: (session?: Session) => session?.canAdmin || session?.canPosItemAdmin, header: "pos", titleKey: "posPrinter", leftHeader: "posSettings" },
      },
      {
        route: "pos/viva-wallet-payment-info-list", moduleId: PLATFORM.moduleName("pos/viva-wallet-payment-info-list"), name: "pos/viva-wallet-payment-info-list", activationStrategy: 'replace', nav: true,
        settings: { permission: (session?: Session) => session?.canAdmin , header: "pos", titleKey: "posVivaWalletPaymentInfo", leftHeader: "posSettings" },
      },
      {
        route: "pos/viva-wallet-payment-info-edit", moduleId: PLATFORM.moduleName("pos/viva-wallet-payment-info-edit"), name: "pos/viva-wallet-payment-info-edit",
        settings: { permission: (session?: Session) => session?.canAdmin , header: "pos", titleKey: "posVivaWalletPaymentInfo", leftHeader: "posSettings" },
      },
      {
        route: "pos/viva-wallet-fee-pos-list", moduleId: PLATFORM.moduleName("pos/viva-wallet-fee-pos-list"), name: "pos/viva-wallet-fee-pos-list", activationStrategy: 'replace', nav: true,
        settings: { permission: (session?: Session) => session?.canAdmin, header: "pos", titleKey: "posVivaWalletFee", leftHeader: "posSettings" },
      },
      {
        route: "pos/viva-wallet-fee-pos-edit", moduleId: PLATFORM.moduleName("pos/viva-wallet-fee-pos-edit"), name: "pos/viva-wallet-fee-pos-edit",
        settings: { permission: (session?: Session) => session?.canAdmin, header: "pos", titleKey: "posVivaWalletFee", leftHeader: "posSettings" },
      },
      {
        route: "pos/viva-wallet-fee-nk-pos-list", moduleId: PLATFORM.moduleName("pos/viva-wallet-fee-nk-pos-list"), name: "pos/viva-wallet-fee-nk-pos-list", activationStrategy: 'replace', nav: true,
        settings: { permission: (session?: Session) => session?.canAdmin, header: "pos", titleKey: "posVivaWalletFeeNkPos", leftHeader: "posSettings" },
      },
      {
        route: "pos/viva-wallet-fee-nk-pos-edit", moduleId: PLATFORM.moduleName("pos/viva-wallet-fee-nk-pos-edit"), name: "pos/viva-wallet-fee-nk-pos-edit",
        settings: { permission: (session?: Session) => session?.canAdmin, header: "pos", titleKey: "posVivaWalletFeeNkPos", leftHeader: "posSettings" },
      },
      {
        route: "pos/viva-wallet-fee-nk-giftcard-list", moduleId: PLATFORM.moduleName("pos/viva-wallet-fee-nk-giftcard-list"), name: "pos/viva-wallet-fee-nk-giftcard-list", activationStrategy: 'replace', nav: true,
        settings: { permission: (session?: Session) => session?.canAdmin, header: "pos", titleKey: "posVivaWalletFeeNkGiftCard", leftHeader: "posSettings" },
      },
      {
        route: "pos/viva-wallet-fee-nk-giftcard-edit", moduleId: PLATFORM.moduleName("pos/viva-wallet-fee-nk-giftcard-edit"), name: "pos/viva-wallet-fee-nk-giftcard-edit",
        settings: { permission: (session?: Session) => session?.canAdmin, header: "pos", titleKey: "posVivaWalletFeeNkGiftCard", leftHeader: "posSettings" },
      },
      {
        route: "pos/receipt-hero-list", moduleId: PLATFORM.moduleName("pos/receipt-hero-list"), name: "pos/receipt-hero-list", activationStrategy: 'replace', nav: true,
        settings: { permission: (session?: Session) => session?.canAdmin || session?.canPosItemAdmin, header: "pos", titleKey: "posReceiptHero", leftHeader: "posSettings" },
      },
      {
        route: "pos/receipt-hero-edit", moduleId: PLATFORM.moduleName("pos/receipt-hero-edit"), name: "pos/receipt-hero-edit",
        settings: { permission: (session?: Session) => session?.canAdmin || session?.canPosItemAdmin, header: "pos", titleKey: "posReceiptHero", leftHeader: "posSettings" },
      },
      // * Own settings
      {
        route: "session/own-info", moduleId: PLATFORM.moduleName("session/own-info"), name: "session/own-info", nav: true,
        settings: { permission: (session?: Session) => session, header: "ownInfo", titleKey: "ownInfo", },
      },
      {
        route: "session/change-password2", moduleId: PLATFORM.moduleName("session/change-password2"), name: "session/change-password2", nav: true,
        settings: { permission: (session?: Session) => session, header: "ownInfo", titleKey: "changePassword", },
      },
      {
        route: "session/otp/setup", moduleId: PLATFORM.moduleName("session/otp/setup"), name: "session/otp/setup", nav: true,
        settings: { permission: (session?: Session) => !session?.canAdmin, header: "ownInfo", titleKey: "otpSetup", },
      },

      // * Logout
      {
        route: "session/logout", moduleId: PLATFORM.moduleName("session/logout"), name: "session/logout",
        settings: { permission: (session?: Session) => session?.actorId, },
      },

      // * without sessions
      { route: "session/otp/init", moduleId: PLATFORM.moduleName("session/otp/init"), name: "session/otp/init", settings: { permission: () => true, } },
      { route: "session/change-password", moduleId: PLATFORM.moduleName("session/change-password"), name: "session/change-password", settings: { permission: () => true, } },
      { route: "session/set-password", moduleId: PLATFORM.moduleName("session/set-password"), name: "session/set-password", settings: { permission: () => true, } },
      { route: "session/forgot-password", moduleId: PLATFORM.moduleName("session/forgot-password"), name: "session/forgot-password", settings: { permission: () => true, } },
      { route: "session/login", moduleId: PLATFORM.moduleName("session/login"), name: "session/login", settings: { permission: () => true, } },
      // * Misc
      { route: "", moduleId: PLATFORM.moduleName("empty"), name: "/", settings: { permission: (session?: Session) => session, } },
    ]);
    this.router = router;
  }

  async activate() {
    const res = await Promise.all([
       this.sessionNotify(),
       this.api.publicTranslateList({all:false}),
    ])
    const translations = res[1];
    reloadTranslations(this.bindingSignaler, this.i18n, translations);
    this.sessionNotifyTimer = setInterval(() => this.sessionNotify(), 120000);
  }

  deactivate() {
    clearInterval(this.sessionNotifyTimer);
  }

  /** Handle session interval data handling */
  async sessionNotify() {
    try {
      let result = await this.api.sessionStatus();
      if (result.abbreviatedSha && this.abbreviatedSha && !result.abbreviatedSha.startsWith("$") && result.abbreviatedSha !== this.abbreviatedSha) {
        /* Attempt to trigger a no-caches reload of app */
        console.log("Application expired", result.abbreviatedSha, "vs", this.abbreviatedSha);
        window.location.reload();
      }
      console.log("Session valid on server: " + !!result.session);
      if (!this.api.session && result.session) {
        this.api.session = result.session;
      }
      if (this.api.session && !result.session) {
        this.api.session = undefined;
        this.router.navigateToRoute("session/login");
      }
      // Display nice element in menu
      this.notifications.integrationMessageErrors = result.integrationMessageErrors;

    } catch (e) {
      // Empty on purpose
    }
  }

  get abbreviatedSha(): string {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore this constant comes from DefinePlugin, typescript won't know about it.
    return ABBREVIATED_SHA;
  }

  async setLocale(locale: string) {
    await this.i18n.setLocale(locale);
    localStorage.setItem("rj-master-language", locale);
  }

  @computedFrom("i18n.i18next.language")
  get locale() {
    return this.i18n.i18next.language;
  }

  async showHelpModal() {
    let current = "/" + this.router.currentInstruction.config.name;
    let language: Language = this.i18n.getLocale() === "en" ? "EN" : "FI";
    this.help = await this.api.helpGetHelp({
      identifier: current, language
    });
    this.showHelp = true;
  }

  toggleTabletMenu(e: Event) {
    this.showTabletMenu = ! this.showTabletMenu;
    e.stopPropagation();
  }

  toggleMobileMenu(e: Event) {
    this.showMobileMenu = ! this.showMobileMenu;
    e.stopPropagation();
  }

  hideMenus() {
    this.showMobileMenu = false;
    this.showTabletMenu = false;
    return true; // Don't call preventDefault
  }
}
